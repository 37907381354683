
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DynamicInput extends Vue {
  @Prop({ default: "" }) variable_name!: string;
  @Prop({ default: false }) status!: boolean;
  @Prop({ default: "" }) label!: string;
  // @Prop({ type: Function, default: () => {} })
  // handleChange!: Function;
  updateVariableName(event: InputEvent) {
    const newValue = (event.target as HTMLInputElement).value;
    this.$emit("input", newValue); // Emit the input event with the new value
  }
  updateStatus(input: InputEvent) {
    this.$emit("status-change", input);
    // console.log(input);
  }
}
