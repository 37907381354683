var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"leave"},[_c('Header'),_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mt-2 height-sb"},[_c('div',{staticClass:"col-sm-2 col-sm-2-border"},[_c('b-button',{staticClass:"btn btn-info btn-sm float-left",staticStyle:{"padding":"10px 20px"},on:{"click":function($event){return _vm.generateSample()}}},[_vm._v(" Generate Sample ")])],1),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h3',[_vm._v("Payroll Configuration")]),_c('hr'),_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Monthly Addition","active":""}},[_vm._l((_vm.getGroupedFields(
                  'monthly_addition'
                )),function(rowFields,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((rowFields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"col-md-6 col-xs-6 col-sm-6 col-lg-6"},[_c('dynamic-input',{attrs:{"label":_vm.generateLabel(field.key),"variable_name":field.value.name,"status":field.value.status},on:{"status-change":function($event){return _vm.handleStatusChange($event, field)}},model:{value:(field.value.name),callback:function ($$v) {_vm.$set(field.value, "name", $$v)},expression:"field.value.name"}})],1)}),0)}),_c('div',[_c('b-button',{staticClass:"btn btn-info btn-sm float-right",staticStyle:{"padding":"10px 20px"},on:{"click":_vm.saveFields}},[_vm._v(" save ")])],1),_c('p',{staticStyle:{"color":"red","margin-top":"4px"}},[_vm._v(_vm._s(_vm.error))])],2),_c('b-tab',{attrs:{"title":"Non Monthly Addition"}},[_vm._l((_vm.getGroupedFields(
                  'non_monthly_addition'
                )),function(rowFields,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((rowFields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"col-md-6 col-xs-6 col-sm-6 col-lg-6"},[_c('dynamic-input',{attrs:{"label":_vm.generateLabel(field.key),"variable_name":field.value.name,"status":field.value.status},on:{"status-change":function($event){return _vm.handleStatusChange($event, field)}},model:{value:(field.value.name),callback:function ($$v) {_vm.$set(field.value, "name", $$v)},expression:"field.value.name"}})],1)}),0)}),_c('div',[_c('b-button',{staticClass:"btn btn-info btn-sm float-right",staticStyle:{"padding":"10px 20px"},on:{"click":_vm.saveFields}},[_vm._v(" save ")])],1),_c('p',{staticStyle:{"color":"red","margin-top":"4px"}},[_vm._v(_vm._s(_vm.error))])],2),_c('b-tab',{attrs:{"title":"Monthly Deduction"}},[_vm._l((_vm.getGroupedFields(
                  'monthly_deduction'
                )),function(rowFields,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((rowFields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"col-md-6 col-xs-6 col-sm-6 col-lg-6"},[_c('dynamic-input',{attrs:{"label":_vm.generateLabel(field.key),"variable_name":field.value.name,"status":field.value.status},on:{"status-change":function($event){return _vm.handleStatusChange($event, field)}},model:{value:(field.value.name),callback:function ($$v) {_vm.$set(field.value, "name", $$v)},expression:"field.value.name"}})],1)}),0)}),_c('div',[_c('b-button',{staticClass:"btn btn-info btn-sm float-right",staticStyle:{"padding":"10px 20px"},on:{"click":_vm.saveFields}},[_vm._v(" save ")])],1),_c('p',{staticStyle:{"color":"red","margin-top":"4px"}},[_vm._v(_vm._s(_vm.error))])],2),_c('b-tab',{attrs:{"title":"Non Monthly Deduction"}},[_vm._l((_vm.getGroupedFields(
                  'non_monthly_deduction'
                )),function(rowFields,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((rowFields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"col-md-6 col-xs-6 col-sm-6 col-lg-6"},[_c('dynamic-input',{attrs:{"label":_vm.generateLabel(field.key),"variable_name":field.value.name,"status":field.value.status},on:{"status-change":function($event){return _vm.handleStatusChange($event, field)}},model:{value:(field.value.name),callback:function ($$v) {_vm.$set(field.value, "name", $$v)},expression:"field.value.name"}})],1)}),0)}),_c('div',[_c('b-button',{staticClass:"btn btn-info btn-sm float-right",staticStyle:{"padding":"10px 20px"},on:{"click":_vm.saveFields}},[_vm._v(" save ")])],1),_c('p',{staticStyle:{"color":"red","margin-top":"4px"}},[_vm._v(_vm._s(_vm.error))])],2)],1)],1)])])])]),_c('PopUp',{attrs:{"modalShow":_vm.modalShow,"message":_vm.modalMessage,"type":_vm.modalType,"messageArray":_vm.modalErrorMessage},on:{"close-modal":_vm.closeModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"heading-text"},[_vm._v(" Payroll Configuration ")]),_c('hr')])
}]

export { render, staticRenderFns }