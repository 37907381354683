
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import DynamicInput from "./dynamicInput.vue";
import hrmService from "@/services/hrm-service";
import builtInService from "@/services/buitin-service";
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils"
@Component({
  components: {
    Header,
    DynamicInput,
    PopUp,
  },
})
export default class PayrollConfiguration extends Vue {
  feilds: any = {};
  groupedFields: { [key: string]: any[][] } = {}; // Define groupedFields object
  error: any = "";
  modalShow = false;
  modalMessage = "";
  modalType = "";
  modalErrorMessage = [];
  errorMessages: any = [];
  saveData = true;

  closeModal() {
    this.modalShow = false;
    this.modalMessage = "";
    this.modalType = "";
    this.errorMessages = [];
  }
  showModal(message: string, type: "success" | "error") {
    this.modalShow = true;
    this.modalMessage = message;
    this.modalType = type;
  }
  showModalErrors(message: [], type: "errorArray") {
    this.modalShow = true;
    this.modalErrorMessage = message;
    this.modalType = type;
  }
  activeTab: any = "";
  handleChange(e: any) {
    console.log(e);
  }
  handleStatusChange(newValue: boolean, field: any) {
    field.value.status = newValue;
  }
  mounted() {
    this.retrieve();
  }
  getGroupedFields(start: string) {
    const groups: any = [];
    let currentGroup: any = [];
    const keys = Object.keys(this.feilds);

    keys.forEach((key, index) => {
      if (this.isMonthlyAdditionSeries(key, start)) {
        currentGroup.push({ key: key, value: this.feilds[key] }); // Push an object with key and value
        if (currentGroup.length === 2 || index === keys.length - 1) {
          groups.push([...currentGroup]);
          currentGroup = [];
        }
      }
    });
    return groups;
  }

  isMonthlyAdditionSeries(key: any, startText: string) {
    return key.startsWith(startText);
  }
  retrieve() {
    hrmService.getPayrollConfig().then((res) => {
      this.feilds = res.data[0].fields_mapping;
      // console.log(res.data);
      // console.log(res.data);
    });
  }
  saveFields() {
    let payload = {
      fields_mapping: this.feilds,
    };
    const invalidFields = utils.getInvalidFields(payload.fields_mapping);
    this.error = "";
    const invalidMessage: any = [];

    if (invalidFields.length > 0) {
      invalidFields.forEach((field) => {
        const message = this.generateLabel(field);
        invalidMessage.push(message);
      });
      this.error = utils.generatePayrollConfigMessage(invalidMessage);
    } else {
      hrmService
        .savePayrollConfig(payload)
        .then((resp) => {
          if (resp) {
            // alert("Updated Successfully");
            this.showModal("Updated Successfully", "success");
          }
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Not updated something went wrong", "error");
        });
    }
  }
  generateLabel(baseLabel: string) {
    const parts = baseLabel.split("_"); // Split by underscore

    if (parts[0] === "non") {
      if (parts.length == 3) {
        parts.push("0");
      }
    } else if (parts[0] === "monthly") {
      if (parts.length == 2) {
        parts.push("0");
      }
    }

    const lastPart = parts[parts.length - 1]; // Get the last part

    // Check if the last part is a number
    const numericValue = parseInt(lastPart);
    const incrementedValue = isNaN(numericValue) ? 1 : numericValue + 1;

    const formattedParts = parts.map((part, index) => {
      if (index === parts.length - 1) {
        if (isNaN(numericValue)) {
          return "1"; // If no numeric value, start from 1
        } else {
          return incrementedValue.toString();
        }
      }
      return part.replace("addition", "allowance");
    });

    const formattedString = formattedParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    return formattedString;
  }
  generateSample() {
    builtInService.generateSample().then((response) => {
      // alert("Sample have been created of Bulk Import Payroll");
      this.showModal("Sample has been created of Bulk Import Payroll", "success");
    });
  }
}
